<template>
  <b-nav tabs>
    <b-nav-item :to="{name: this.$routeName.CLIENT_EDIT}" exact exact-active-class="active">Основная информация</b-nav-item>
    <b-nav-item :to="{name: this.$routeName.CLIENT_EDIT_FAMILY}" exact exact-active-class="active">Семья</b-nav-item>
    <b-nav-item :to="{name: this.$routeName.CLIENT_EDIT_TRAINER}" exact exact-active-class="active">Тренеры</b-nav-item>
    <b-nav-item :to="{name: this.$routeName.CLIENT_EDIT_ADDRESS}" exact exact-active-class="active">Адреса</b-nav-item>
  </b-nav>
</template>


<script>

export default {
  name: 'ClientEditTab'
};

</script>
