<template>
  <ClientEditTemplate >
    <div class="row">
      <div class="col-4">
        <FveSelectValue
          label="Тип адреса"
          v-model="form.type"
          :optionList="addressTypeList"
          :optionGetKey="(obj) => obj.key"
          :optionGetName="(obj) => obj.value"
        />
      </div>
      <div class="col-4">
        <FveSelectAjaxV2Address
          label="Адрес"
          required
          v-model="form.address"
        />
        <span
          v-if="!addressFormDetails"
          @click="addressFormDetails=!addressFormDetails"
          style="cursor: pointer; color: var(--text-descr); position: relative; top: -20px;"
        >Редактировать вручную</span>
      </div>
      <div class="col-12" v-if="addressFormDetails">
        <FveSelectAjaxV2AddressUser
          label="Детали адреса"
          required
          v-model="form.address"
        />
      </div>
      <div class="col-12">
        <div class="btn-gp mb-3" style="/*padding-top: 24px;*/">
          <b-button size="lg" variant="danger" @click="addAddress">Добавить</b-button>
          <!--<b-button size="lg" variant="link" @click="$emit('cancel')">Отмена</b-button>-->
        </div>
      </div>
    </div>

    <div class="row">
      <template v-for="(item, index) in addressList">
        <div class="col-4 mb-md" :key="index">
          <CardAddress
            :type="item.type"
            :text="item.address"

            @remove="remove(item)"
          />
        </div>
      </template>
    </div>

  </ClientEditTemplate>
</template>

<script>

import ClientEditTemplate from "@page/Client/_component/ClientEditTemplate";
import CardAddress from "@component/Module/CardAddress";
// import FveText from "@widgetFormValidate/src/Element/Text/FveText";
import FveSelectValue from "@widgetFormValidate/src/Element/Select/vue-multiselect/FveSelectValue";
import FveSelectAjaxV2Address from "@widgetFormValidate/src/Element/Custom/FveSelectAjaxV2Address";
import FveSelectAjaxV2AddressUser from "@widgetFormValidate/src/Element/Custom/FveSelectAjaxV2AddressUser";

import GetGoogleMap from "@library/GetGoogleMap";

export default {
  name: "ClientEditAddress",
  components: {
    ClientEditTemplate,
    CardAddress,
    // FveText,
    FveSelectValue,
    FveSelectAjaxV2Address,
    FveSelectAjaxV2AddressUser,
  },
  props: {
    clientId: [String, Number]
  },

  data(){
    return this.getDefaultData();
  },
  methods:{
    getDefaultData() {
      return {
        form: {
          address: {
            value: '',
            data: {
              geo_lat: null,
              geo_lon: null,
            }
          },
          type: '',
        },
        addressFormDetails: false,
        addressList: [],
        addressTypeList: [],
      };
    },
    load(){

      RequestManager.Reference.getAddressList({}).then((res) => {
        this.addressTypeList = res;

        RequestManager.Client.getClientAddressById({
          id: this.clientId
        }).then((response) => {
          this.addressList = response.results;

          for(let i = 0; i < this.addressList.length; i++) {
            let typeName = this.addressTypeList.find((item) => { return item.key === this.addressList[i].type; });
            this.addressList[i].type = typeName && typeName.value ? typeName.value : this.addressList[i].type;
          }
        });

      });
    },
    reload(){
      Object.assign(this, this.getDefaultData());
      this.load();
    },

    addAddressSend(){
      let sendData = Object.assign({}, this.form);
      sendData.location = {
        latitude: sendData.address.data.geo_lat,
        longitude: sendData.address.data.geo_lon
      };
      sendData.address = sendData.address.value;

      RequestManager.Client.addClientAddress({
        clientId: this.clientId,
        address: sendData
      }).then(this.reload);
    },
    addAddress(){
      if(!this.form.address || !this.form.type) {
        return;
      }

      GetGoogleMap().then(({getAddressCoordinate}) => {
        getAddressCoordinate(this.form.address.value).then(
          (success) => {
            this.form.address.data.geo_lat = success.lat;
            this.form.address.data.geo_lon = success.lon;
            this.addAddressSend();
          },
          error => {
            this.$dialogs.alert('Не удалось определить адресс');
          }
        );
      });
    },

    remove(address){
      const options = {title: 'Удалить адрес?', cancelLabel: 'Нет', okLabel: 'Да'};
      this.$dialogs.confirm('Удалить адрес?', options)
        .then((res) => { // {ok: true|false|undefined}
          if(res && res.ok) {
            RequestManager.Client.deleteClientAddress({
              clientId: this.clientId,
              addressId: address ? address.id : null,
            }).then(this.reload);
          }
        });
    }
  },
  mounted() {
    this.load();
  }
};
</script>

<style lang="scss" scoped>

</style>
