<template>
  <div class="card-address">
    <div class="card-address__body">
      <div class="card-address__content">
        <div class="card-address__type">{{ type }}</div>
        <div class="card-address__text">{{ text }}</div>
      </div>
      <div class="card-address__control" @click="$emit('remove')">
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 12.81 13.83" v-bind:svg-inline="''" v-bind:class="'card-address__icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M6.38 13.83H3.76A2.22 2.22 0 011.5 11.7C1.29 9.8.93 5.33.93 5.29a.75.75 0 111.5-.12s.36 4.49.57 6.36c.07.67.46.77.8.77h5.19a.75.75 0 00.84-.78c.21-1.86.57-6.31.57-6.36a.76.76 0 01.81-.69.75.75 0 01.69.81s-.37 4.52-.58 6.4A2.24 2.24 0 019 13.81zM12.06 3.83H.75a.75.75 0 010-1.5h11.31a.75.75 0 010 1.5z"/><path d="M9.88 3.83a1.85 1.85 0 01-1.81-1.49l-.17-.81a.2.2 0 00-.09 0H5a.1.1 0 00-.1.08l-.15.76a1.85 1.85 0 01-1.82 1.46.75.75 0 010-1.5.35.35 0 00.34-.33l.16-.81A1.62 1.62 0 015 0h2.81a1.6 1.6 0 011.55 1.19l.18.81a.35.35 0 00.34.28.75.75 0 110 1.5z"/></svg>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "CardAddress",
  props: {
    type: String,
    text: String,
  }
};
</script>

<style lang="scss" scoped>

// card-family
.card-address {
  position: relative;
  padding: 12px 16px;
  background-color: var(--br-block-prime);
  border-radius: 8px;
  cursor: pointer;
  .card-address__body {
    display: flex;
    .card-address__content {
      .card-address__type {
        display: inline-block;
        margin-bottom: 14px;
        padding: 6px 10px 4px;
        color: var(--color-prime);
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
        border: 1px solid #777971;
        border-radius: 16px
      }
      .card-address__text {
        color: var(--text-prime);
        font-size: 17px;
      }
    }
  }
  .card-address__control {
    display: flex;
    position: absolute;
    top: 8px;
    right: 8px;
    opacity: 0;
    visibility: hidden;
    transition: all .2s ease-in-out;
    .card-address__icon {
      margin-right: 5px;
      cursor: pointer;
      path {
        fill: var(--color-prime);
        transition: all .2s ease-in-out;
      }
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        path {
          fill: var(--color-sub);
        }
      }
    }
  }
  &:hover {
    .card-address__control {
      opacity: 1;
      visibility: visible;
    }
  }
}

</style>
